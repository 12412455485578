<template>
	<div class="pageview">
		<el-scrollbar>
			<div class="pageviewtitle">
				<div class="titletab">题目列表</div>
			</div>
			<div class="buttonview">
				<el-button type="primary" @click="inadd">添加题目</el-button>
			</div>
			<el-table :data="list" style="width: 100%" stripe border>
				<el-table-column type="index" align="center" width="50" />
				<el-table-column align="center" label="题目">
					<template #default="scope">
						<div class="nowarp">{{scope.row.questionName}}</div>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="subjectName" label="学科" />
				<el-table-column align="center" prop="gradeName" label="年级" />
				<el-table-column align="center" label="难度">
					<template #default="scope">
						<el-tag size="small" type="danger" v-if="scope.row.difficulty == 0">超简单</el-tag>
						<el-tag size="small" type="danger" v-if="scope.row.difficulty == 1">简单</el-tag>
						<el-tag size="small" type="danger" v-if="scope.row.difficulty == 2">正常</el-tag>
						<el-tag size="small" type="danger" v-if="scope.row.difficulty == 3">难</el-tag>
						<el-tag size="small" type="danger" v-if="scope.row.difficulty == 4">超难</el-tag>
					</template>
				</el-table-column>
				<el-table-column label='操作' align="center">
					<template #default="scope">
						<el-button size="small" type="success" @click="getquestionsinfo(scope.row.id)">详情
						</el-button>
						<el-button size="small" type="primary" @click="edits(scope.row)">修改
						</el-button>
						<el-button size="small" type="danger" @click="deletes(scope.row)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="elpagination">
				<el-pagination background layout="prev, pager, next" @current-change="pagechange" :page-count="pages" />
			</div>
		</el-scrollbar>
	</div>
</template>

<script>
	import {
		questionslist,
		questionsdelete
	} from "../../util/api"
	export default {
		data() {
			return {
				pageNum: 1,
				pageSize: 15,
				pages: 1,
				list: null
			}
		},
		created() {
			this.getlist()
		},
		methods: {
			deletes(row){
				this.$confirm('是否删除“' + row.questionName + '”？', '提示', {
					confirmButtonText: '是',
					cancelButtonText: '否',
					type: 'error',
				}).then(() => {
					questionsdelete(row.id).then((res)=>{
						this.$message({
							type: 'success',
							message: '删除成功',
						})
						this.getlist()
					})
				})
			},
			inadd(){
				this.$router.push('/home/questionadd')
			},
			getquestionsinfo(id){
				this.$router.push({
					path: '/home/questioninfo',
					query: {
						id: id
					}
				})
			},
			getlist() { //获取列表
				questionslist({
					pageNum: this.pageNum,
					pageSize: this.pageSize,
					parentId: 0
				}).then((res)=>{
					this.list = res.data.list
					this.pages = res.data.pages
				})
			},
			pagechange(index) {
				this.pageNum = index
				this.getlist()
			}
		}
	}
</script>

<style scoped>
	.nowarp{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-align: left;
	}
</style>
